import "./ContactInformations.css";
import React from "react";
import {Link} from "react-router-dom";

const ContactInformations = () => {

    const PHONE_NUMBER = "06 04 11 76 56";

    return (
        <article className="contact-informations">
            <h2>Un devis ? Une question ?</h2>
            <p>Envie de vitres vraiment propres ? Rejoignez l’aventure !</p>
            <p><strong>Contactez-nous pour votre devis sur-mesure !</strong></p>

            <section className="contact-buttons">
                <button className="button phone-button salmon-button"><a href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a></button>
                <button className="button mail-button"><Link to={"/contact"}>Par email</Link></button>
            </section>
        </article>
    );
}

export default ContactInformations;