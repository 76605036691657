import Button from "../common/Button";
import ContactInformations from "../common/ContactInformations";
import "./Accueil.css";
import "../../utils/font/shopia.ttf";
import React from "react";
import CustomCarousel from "./CustomCarousel";

const Accueil = () => {

    return (
        <div className="accueil-container">
            <div className="background-image background-accueil">
                <img alt="logo à la belle devanture" src="/images/icons/logo_plein_blanc.png" className="logo"/>
            </div>
            <h1 id="bienvenue">Bienvenue à la belle devanture,</h1>
            <h2>votre entreprise de lavage de vitres écologique à Lille !</h2>
            <p className="description-entreprise">
                Nous sommes une entreprise de passionnés, déterminés à offrir un service de qualité tout en respectant
                l’environnement. Notre démarche écologique est au cœur de notre métier, et nous sommes fiers de
                contribuer à la protection de l’environnement tout en proposant un service efficace et professionnel.

                <strong> Découvrez notre entreprise et nos services en parcourant notre site !</strong>
            </p>

            <Button text="Nos services" color="blue-button" navigate="services"/>

            <div className="sided-images">
                <img alt="vélo cargo à la belle devanture" src="/images/photos/accueil_2.webp"/>
                <img alt="femme lavant une vitre" src="/images/photos/accueil_3.webp"/>
            </div>

            <article className="engagements">
                <h2 id="engagements">Nos engagements :</h2>

                <section className="proximite-container">
                    <div>
                        <h3>La proximité avant tout !</h3>
                        <p>Nous parcourons quotidiennement les rues de Lille et intervenons avec <strong>sourire,
                            efficacité et discrétion </strong>
                            auprès de chacun de nos clients, que nous connaissons individuellement.
                            Cette relation de proximité est pour nous un gage de qualité et notre priorité !
                        </p>
                    </div>
                    <img alt="Engagement proximité" src="/images/icons/engagement-1.png" />
                </section>

                <section>
                    <img alt="Engagement propreté" src="/images/icons/engagement-2.png"/>
                    <div>
                        <h3>Des vitres propres durablement</h3>
                        <p>Nous utilisons de l’<strong>eau additionnée de liquide vaisselle</strong> afin
                            de nettoyer en profondeur vos vitres et laisser une pellicule protectrice qui évitera que la
                            poussière ne s’accroche.
                            Et pour garantir un résultat optimal nous sommes équipés de <strong>matériel
                                professionnel</strong> :
                            raclette en caoutchouc pour retirer l’eau sans laisser une trace et perche pour atteindre
                            les endroits les moins atteignables !
                        </p>
                    </div>
                </section>

                <section>
                    <div>
                        <h3>Une démarche 100% écologique</h3>
                        <p>Nous avons choisi de n’utiliser que des <strong>produits écologiques, naturels et respectueux
                            de
                            l’environnement </strong>
                            pour le nettoyage de vos vitres. Nous effectuons également tous nos déplacements à vélo,
                            ce qui nous permet de réduire notre empreinte carbone et de promouvoir un mode de vie sain
                            et
                            durable.
                        </p>
                    </div>
                    <img alt="Engagement écologie" src="/images/icons/engagement-3.png"/>
                </section>

                <section>
                    <img alt="Engagement seconde vie" src="/images/icons/engagement-4.png"/>
                    <div>
                        <h3>Un engagement jusqu'aux détails...</h3>
                        <p>Pour nettoyer les entourages des fenêtres, les soubassements et les châssis, nous utilisons
                            des
                            chiffons. Mais pas n’importe lesquels :
                            <strong> nous réutilisons les serviettes coton éponge, que vous avez utilisées</strong>,
                            usées et données au RELAIS, qui les ont collectées, triées et conditionnées... En chiffons !
                        </p>
                    </div>
                </section>
                <Button text="En savoir +" color="blue-button" navigate="a-propos"/>
            </article>

            <article className="carousel-container">
                <h2 id="ils-nous-font-confiance">Ils nous font confiance</h2>
                <CustomCarousel/>
            </article>

            <ContactInformations/>
        </div>
    );

}

export default Accueil;