import "./Footer.css";

const Footer = () => {

    return (
        <footer>
            <section>
                <p>A la belle devanture Grands Ensemble - Coopérative d’activités et d’emploi</p>
                <p>BAZAAR ST SO - 292 rue Camille Guérin 59800 LILLE</p>
                <p>Tél. : 03.20.08.06.97 - Mail : facturation@smart.coop</p>
                <p>SA SCOP - N° SIREN : 488 458 969 - Code APE : 7022Z - TVA Intra. : FR53488458969</p>
                <p>Webdesigner : Blandine Beutter - Panel Graphisme | Développeuse : Amandine Watrelos</p>
            </section>

            <section className="social-medias">
                <a href="https://www.facebook.com/alabelledevanture" target="_blank" rel="noreferrer">
                    <img alt="Facebook" src="/images/icons/facebook.png"/>
                </a>
                <a href="https://www.instagram.com/alabelledevanture/" target="_blank" rel="noreferrer">
                    <img alt="Instagram" src="/images/icons/instagram.png"/>
                </a>
                <a href="https://www.linkedin.com/company/%C3%A0-la-belle-devanture/about/" target="_blank" rel="noreferrer">
                    <img alt="LinkedIn" src="/images/icons/linkedIn.png"/>
                </a>
            </section>
        </footer>
    );

}
export default Footer;