import "./Navbar.css";
import React from "react";
// @ts-ignore
import {HashLink as Link} from 'react-router-hash-link';
import BurgerMenu from "./BurgerMenu";


const Navbar = () => {

    return (
        <header>
            {/* BurgerMenu is displayed in responsive mode, otherwise top-navbar will be displayed. */}
            <nav className="responsive-navbar">
                <BurgerMenu/>
            </nav>
            <nav className="top-navbar">
                <ul className="nav-links">
                    <li className="has-dropdown"><Link to={"/"}>ACCUEIL</Link>
                        <ul className="dropdown">
                            <li className="bottom-border"><Link to={"/#bienvenue"}>Bienvenue !</Link></li>
                            <li className="bottom-border"><Link to={"/#engagements"}>Nos engagements</Link></li>
                            <li><Link to={"/#ils-nous-font-confiance"}>Ils nous font confiance</Link></li>
                        </ul>
                    </li>
                    <li>|</li>
                    <li className="has-dropdown"><Link to={"/services"}>SERVICES</Link>
                        <ul className="dropdown">
                            <li className="bottom-border"><Link to={"/services/#professionnels"}>Professionnels</Link></li>
                            <li><Link to={"/services/#particuliers"}>Particuliers</Link></li>
                        </ul>
                    </li>
                    <li>|</li>
                    <li className="has-dropdown"><Link to={"/a-propos"}>À PROPOS</Link>
                        <ul className="dropdown">
                            <li className="bottom-border"><Link to={"/a-propos/#lille"}>Lille</Link></li>
                            <li className="bottom-border"><Link to={"/a-propos/#sylvain"}>Vannes</Link></li>
                            <li><Link to={"/a-propos/#nous-rejoindre"}>Nous rejoindre</Link></li>
                        </ul>
                    </li>
                    <li>|</li>
                    <li className="has-dropdown"><Link to={"/contact"}>CONTACT - DEVIS</Link></li>
                </ul>
            </nav>
        </header>
    );
}

export default Navbar;