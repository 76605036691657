import "./Button.css";
import {Link} from "react-router-dom";
import React, {FC} from "react";

interface ButtonProps {
    text: string,
    color: string,
    navigate?: string,
}

const Button: FC<ButtonProps> = ({text, color, navigate}) => {

    const getButtonText = (): JSX.Element => {
        const page = navigate;
        if (!page) {
            return <p>{text}</p>;
        } else {
            return <Link to={`/${page}`}>{text}</Link>;
        }
    }

    return (
        <button className={`button ${color}`}>{getButtonText()}</button>
    );
}

export default Button;